import React, { useState, useEffect } from "react";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import Seo from "../components/Seo/Seo";
import StripHTML from "../utils/StripHTML";
// import GImage from "../components/Picture/GImage";
import Pratiques from "../components/Pratiques/Pratiques";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import PdfViewer from "../components/PdfViewer/PdfViewer";
import { graphql, Link } from "gatsby";

const slugifyConf = {
  lower: true,
  strict: true,
};

const CategoryTemplate = (props) => {
  const [keywordsPractices, setKeywordsPractices] = useState([]);
  const [filteredPratiques, setFilteredPratiques] = useState([]);
  const [tagPractice, setTagPractice] = useState("");
  const { directus } = props.data;
  const slugify = require("slugify");

  const resetSearch = () => {
    // localStorage.setItem("tag_practice", "");
    setTagPractice("");
    return true;
  };

  useEffect(() => {
    // if (localStorage.getItem("tag_practice")) {
    //   setTagPractice(localStorage.getItem("tag_practice"));
    // }
    if (directus.categories_by_id.practices && keywordsPractices.length === 0) {
      directus.categories_by_id.practices.forEach((practice) => {
        practice.practices_id.keywords.forEach((keyword) => {
          if (keyword && keyword.keywords_id.name) {
            const keywordsPracticesNames = keywordsPractices.map(
              (keywordsPractices) => keywordsPractices.name
            );

            if (!keywordsPracticesNames.includes(keyword.keywords_id.name)) {
              setKeywordsPractices((keywordsPractices) => [
                ...keywordsPractices,
                {
                  name: keyword.keywords_id.name,
                  pratiques:
                    keyword.keywords_id.pratiques &&
                    keyword.keywords_id.pratiques.length,
                },
              ]);

              keywordsPractices.push({
                name: keyword.keywords_id.name,
                pratiques:
                  keyword.keywords_id.pratiques &&
                  keyword.keywords_id.pratiques.length,
              });
            }
          }
        });
      });
    }

    if (directus.categories_by_id.practices) {
      setFilteredPratiques(
        directus.categories_by_id.practices.filter((practice) => {
          if (tagPractice === "") {
            return true;
          } else if (tagPractice.length > 0 && practice.practices_id.keywords) {
            var keywordsMatched = practice.practices_id.keywords.map(
              (keyword) => {
                if (keyword.keywords_id !== null) {
                  return keyword.keywords_id.name === tagPractice;
                } else {
                  return false;
                }
              }
            );
            for (var i = 0; i < keywordsMatched.length; i++) {
              if (keywordsMatched[i]) {
                return true;
              }
            }
          }
          return false;
        })
      );
    }
  }, [directus, tagPractice, keywordsPractices, keywordsPractices.length]);

  const changeTagPractice = (newTagPractice) => {
    if (newTagPractice === tagPractice) {
      // localStorage.setItem("tag_practice", "");
      setTagPractice("");
    } else {
      // localStorage.setItem("tag_practice", newTagPractice);
      setTagPractice(newTagPractice);
    }
    // navigate("/pratiques#liste");
  };

  const sizeFromLength = (nb) => {
    const averagekeywordsPractices =
      keywordsPractices.reduce((accu, current) => {
        return parseInt(accu) + parseInt(current.pratiques);
      }, 0) / parseInt(keywordsPractices.length);

    const wordLength1 = parseInt(averagekeywordsPractices) + 1;
    const wordLength2 = parseInt(averagekeywordsPractices);
    const wordLength3 = parseInt(averagekeywordsPractices) - 1;

    var classTextSize = "text-sm";
    if (nb >= wordLength1) {
      classTextSize = "text-2xl md:text-2xl5 leading-7";
    } else if (nb >= wordLength2) {
      classTextSize = "text-lg md:text-xl leading-5";
    } else if (nb >= wordLength3) {
      classTextSize = "text-base md:text-lg leading-4";
    } else {
      classTextSize = "text-sm md:text-base leading-4";
    }
    return classTextSize;
  };

  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      {/* {directus.settings &&
        directus.settings.pratiques_illustration !== null && (
          <GImage
            className="h-44 md:h-72 bg-categories bg-cover"
            src={directus.settings.pratiques_illustration.imageFile}
            alt="Image de la page d'une activité du secteur langues du Groupe Français d'éducation Nouvelle"
          />
        )} */}
      <Seo
        title={directus.categories_by_id.title}
        description={`${directus.categories_by_id.title} : ${
          directus.categories_by_id.intro &&
          directus.categories_by_id.intro.length > 1
            ? StripHTML(directus.categories_by_id.intro)
            : directus.categories_by_id.description &&
              directus.categories_by_id.description.length > 1
            ? StripHTML(directus.categories_by_id.description)
            : "pratique du " + config.siteTitleShort
        }`}
        article={false}
      />
      <div className="category-container">
        <Breadcrumb location={"pratiques"} />
        {directus.categories_by_id && (
          <div className="categories max-w-6xl mx-auto px-3 sm:px-6 pt-8 flex items-center">
            <div className="mx-auto w-auto">
              <h1 className="text-center mt-6 mb-8">
                {directus.categories_by_id.title}
              </h1>
              <hr className="bg-red-500 border-none h-2" />
              {directus.categories_by_id.description && (
                <div
                  className="mt-6 pt-10 text-editor"
                  dangerouslySetInnerHTML={{
                    __html: `${directus.categories_by_id.description}`,
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div className="practices-container grid grid-cols-1 gap-12 max-w-6xl mx-auto px-3 sm:px-6 pt-6 pb-20">
          {keywordsPractices && keywordsPractices.length > 0 ? (
            <div className="">
              <h4 className="text-center pl-3 mb-2">Rechercher par mot-clé</h4>
              <div className="transform relative bg-white py-8 px-4 text-center max-w-3xl w-auto mx-auto rounded shadow-lg">
                <div className="flex flex-wrap items-center justify-center">
                  {keywordsPractices.map((keyword, keyword_index) => {
                    return keyword ? (
                      <button
                        key={keyword_index}
                        onClick={() => changeTagPractice(keyword.name)}
                        className={`text-gray-700 p-1 cursor-pointer ${sizeFromLength(
                          keyword.pratiques
                        )} ${
                          tagPractice === keyword.name
                            ? "font-bold text-red-500"
                            : "text-gray-700"
                        }`}
                      >
                        {keyword.name}
                      </button>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>
              <p className="flex mt-10">
                <span className="text-sm md:text-base">
                  {filteredPratiques.length}
                </span>
                <span className="ml-1 text-sm md:text-base">
                  pratique{filteredPratiques.length > 1 && "s"}
                  <span className="hidden sm:inline-block sm:pl-1">
                    trouvée{filteredPratiques.length > 1 && "s"}
                  </span>
                </span>
                {tagPractice && (
                  <span className="text-sm md:text-base ml-4 pl-3 border-l-2 border-gray-400">
                    <strong className="hidden sm:inline-block">
                      Mot-clé :{" "}
                    </strong>
                    <button
                      onClick={() => changeTagPractice(tagPractice)}
                      title="Retirer de la recherche"
                      className="px-2 text-gray-600"
                    >
                      {tagPractice}{" "}
                      <span className="material-icons-outlined text-sm text-gray-600">
                        close
                      </span>
                    </button>
                  </span>
                )}
              </p>
              <hr className={`w-full my-2 bg-red-500 border-none h-2`} />
            </div>
          ) : (
            ""
          )}
          {filteredPratiques.length > 0 ? (
            filteredPratiques.map((practice, practice_index) => {
              return (
                <div
                  key={practice_index}
                  className={`
                    ${
                      props.location.hash &&
                      "#" +
                        slugify(practice.practices_id.title, slugifyConf) ===
                        props.location.hash &&
                      "border-0 border-l-4 border-red-100 pl-4"
                    }
                  `}
                  id={slugify(practice.practices_id.title, slugifyConf)}
                >
                  <h4 className="mb-1">
                    {practice.practices_id.description &&
                    practice.practices_id.description.length > 3 ? (
                      <Link
                        to={`/pratiques/${slugify(
                          directus.categories_by_id.title,
                          slugifyConf
                        )}/${slugify(
                          practice.practices_id.title,
                          slugifyConf
                        )}`}
                      >
                        {practice.practices_id.title}
                      </Link>
                    ) : (
                      practice.practices_id.title
                    )}
                  </h4>
                  {practice.practices_id.intro && (
                    <p className="my-3 text-editor">
                      {practice
                        ? practice.practices_id.intro
                            .split("\n")
                            .map((item, key) => {
                              return (
                                <React.Fragment key={key}>
                                  {item}
                                  <br />
                                </React.Fragment>
                              );
                            })
                        : ""}
                    </p>
                  )}
                  {practice.practices_id.description &&
                    practice.practices_id.description.length > 3 && (
                      <Link
                        className="btn-sm bg-white text-gray-600 hover:text-gray-900"
                        to={`/pratiques/${slugify(
                          directus.categories_by_id.title,
                          slugifyConf
                        )}/${slugify(
                          practice.practices_id.title,
                          slugifyConf
                        )}`}
                      >
                        <span className="pr-2 material-icons-outlined inherit">
                          more_horiz
                        </span>
                        Lire la suite
                      </Link>
                    )}
                  <ul className="mt-2">
                    {practice.practices_id.documents.map((document) => {
                      return (
                        document &&
                        document.directus_files_id !== null && (
                          <li
                            className={`my-2 px-3 bg-white mb-3 shadow-md ${
                              document.directus_files_id.description
                                ? "py-2"
                                : "py-2"
                            }`}
                            key={document.directus_files_id.id}
                          >
                            <div className="flex items-center w-full">
                              <a
                                title="Télécharger le document"
                                className={`inline-flex items-center px-2 py-2 hover:bg-gray-100`}
                                target="_blank"
                                rel="noreferrer"
                                href={
                                  document.directus_files_id.imageFile.publicURL
                                }
                              >
                                <span className="material-icons-outlined text-gray-600 hover:bg-gray-100">
                                  file_download
                                </span>
                              </a>
                              {document.directus_files_id.imageFile
                                .publicURL && (
                                <PdfViewer
                                  title={document.directus_files_id.title}
                                  extension={
                                    document.directus_files_id.imageFile
                                      .extension
                                  }
                                  textClass="text-gray-600"
                                  pdfFile={
                                    document.directus_files_id.imageFile
                                      .publicURL
                                  }
                                />
                              )}
                              <span className="ml-2 font-normal text-base">
                                {document.directus_files_id.title}
                              </span>
                            </div>
                            {document.directus_files_id.description && (
                              <p className="pb-2 px-2">
                                <i className="text-gray-600 text-sm">
                                  {document.directus_files_id.description}
                                </i>
                              </p>
                            )}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              );
            })
          ) : (
            <div>
              <p className="h1 my-4">Aucun résultat</p>
              <button
                className="btn-sm"
                type="button"
                onClick={() => resetSearch()}
              >
                Réinitialiser mes critères de recherche
              </button>
            </div>
          )}
        </div>
      </div>
      {directus.categories && (
        <Pratiques categories={directus.categories} title="Autres pratiques" />
      )}
    </Layout>
  );
};
export default CategoryTemplate;

export const query = graphql`
  query ($id: ID!, $title: String!) {
    directus: allDirectus {
      # settings {
      #   id
      #   pratiques_illustration {
      #     id
      #     imageFile {
      #       id
      #       childImageSharp {
      #         id
      #         gatsbyImageData(
      #           quality: 80
      #           layout: FULL_WIDTH
      #           placeholder: TRACED_SVG
      #           tracedSVGOptions: { color: "#d8b0b1" }
      #         )
      #       }
      #     }
      #   }
      # }
      categories(sort: "sort", filter: { title: { _neq: $title } }) {
        id
        title
      }
      categories_by_id(id: $id) {
        id
        title
        description
        practices {
          id
          practices_id {
            intro
            description
            title
            keywords {
              keywords_id {
                name
                pratiques(
                  filter: {
                    practices_id: {
                      categories: { categories_id: { title: { _eq: $title } } }
                    }
                  }
                ) {
                  id
                }
              }
            }
            documents {
              directus_files_id {
                id
                title
                filename_disk
                filename_download
                description
                imageFile {
                  id
                  extension
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`;
